@import "../../../styles/_var.scss";

.trade {
    position: relative;
    z-index: 2;
    &__body {
        position: relative;
        z-index: 2;
        margin-top: 100px;
        // margin-bottom: 200px;
        padding: 100px 100px 0 100px;
        height: 831px;

        position: relative;
        @media (max-width: $md1+px) {
            height: 904px;
            padding: 100px 20px 0 40px;
        }
        @media (max-width: 992px) {
            height: 694px;
        }
        @media (max-width: 768px) {
            padding: 75px 0px 0 0px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            bottom: 0;
            position: absolute;
            width: 100%;

            height: 0%;
            opacity: 0;
            background-color: #3f778a;
            transition: all 1.5s linear;
            @media (max-width: 768px) {
                width: 120%;
                left: -20px;
            }
        }
    }

    &__wrap {
        position: relative;
        z-index: 3;
        overflow: hidden;
        padding-bottom: 30px;
    }
    &__title,
    &__subtitle,
    &__startbtn {
        position: relative;
        z-index: 3;
    }
    &__title {
        font-family: "Rubik", sans-serif;

        line-height: 1;
        color: #ffffff;
        margin-bottom: 10px;
        &:global(.pl) {
            max-width: 900px;
        }
    }

    &__subtitle {
        color: #c5d6dc;
        font-size: 36px;
        line-height: 1;
        margin-bottom: 40px;
        &:global(.pl) {
            max-width: 850px;
        }
        @media (max-width: 992px) {
            font-size: 24px;
        }
        @media (max-width: 380px) {
            font-size: 20px;
        }
    }

    &__startbtn {
        // width: 166px;
        height: 61px;
        background: #ffffff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        font-size: 20px;
        line-height: 61px;
        text-align: center;
        color: #000000;
        cursor: pointer;
        display: inline-block;
        padding: 0px 30px;
        @media (max-width: $md6+px) {
            font-size: 16px;
            height: 49px;
            padding: 0px 22px;
            line-height: 49px;
        }
    }
    &__title,
    &__subtitle,
    &__startbtn {
        position: relative;
        left: -100%;
        transition: all 2s linear 1.5s;
    }
}
.trade.active {
    .trade__body {
        &:after {
            height: 100%;
            opacity: 1;
        }
    }
    .trade__title,
    .trade__subtitle,
    .trade__startbtn {
        left: 0;
    }
    .trade__startbtn {
        left: 140px;
        @media (max-width: $md4+px) {
            left: 70px;
        }
        @media (max-width: 380px) {
            left: 50px;
        }
    }
}

.ball {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    width: 1512px;

    height: 100%;
    overflow: visible;
    z-index: 3;
    @media (max-width: 1512px) {
        width: 1000px;
    }
    @media (max-width: $md4+px) {
        width: 100px;
    }
    @media (max-width: $md5+px) {
        width: 1000px;
    }
    &__wrap {
        position: absolute;
        z-index: 4;
        display: block;
        transition-property: width, top, right, left;
        transition: 1.5s linear;
        top: 400px;
        transform: rotate(-28deg);
        width: 400px;
        left: 850px;
        @media (max-width: 1512px) {
            left: 500px;
        }
        @media (max-width: $md3+px) {
            left: 400px;
        }
        @media (max-width: $md4+px) {
            left: -50px;
            width: 300px;
            top: 350px;
        }
        @media (max-width: $md5+px) {
            left: 250px;
            top: 300px;
        }
        @media (max-width: 600px) {
            left: 50px;
            top: 370px;
            width: 250px;
        }
        @media (max-width: 500px) {
            left: 0;
            width: 230px;
            top: 350px;
        }
        img {
            transition-property: width, top, right;
            transition: 1.5s linear;
            width: 100%;
        }
    }
    &.first_a {
        .ball__wrap {
            width: 500px;
            top: 350px;

            transform: rotate(-12deg);
            @media (max-width: $md4+px) {
                left: -50px;
                width: 350px;
                top: 350px;
            }
            @media (max-width: $md5+px) {
                left: 250px;
                top: 300px;
            }
            @media (max-width: 600px) {
                left: 100px;
                top: 380px;
                width: 270px;
            }
            @media (max-width: 500px) {
                left: 30px;
                width: 250px;
                top: 360px;
            }
        }
    }
    &.second_a {
        .ball__wrap {
            top: 85px;
            width: 820px;
            left: 700px;
            transform: rotate(0deg);
            @media (max-width: 1512px) {
                left: 300px;
            }
            @media (max-width: $md2+px) {
                width: 700px;
                top: 180px;
                left: 200px;
            }
            @media (max-width: $md4+px) {
                left: -120px;
                width: 550px;
                top: 220px;
            }
            @media (max-width: $md5+px) {
                left: 200px;
                width: 500px;
                top: 220px;
            }
            @media (max-width: 700px) {
                width: 450px;
                top: 260px;
                left: 180px;
            }
            @media (max-width: 620px) {
                width: 420px;
                left: 120px;
                top: 300px;
            }
            @media (max-width: 540px) {
                width: 370px;
                left: 80px;
                top: 330px;
            }
            @media (max-width: 450px) {
                width: 340px;
                left: 0px;
                top: 350px;
            }
        }
    }
    &.three_a {
        .ball__wrap {
            opacity: 0.3;
            top: 503px;
            width: 1688px;
            left: -198px;
            transform: rotate(8deg);
            @media (max-width: $md1+px) {
                width: 1335px;
                left: 0px;
                top: 840px;
            }
            @media (max-width: 1512px) {
                left: -254px;
            }
            @media (max-width: $md2+px) {
                left: -206px;
                top: 800px;
                width: 1340px;
            }
            @media (max-width: 1324px) {
                top: 1025px;
            }
            @media (max-width: $md3+px) {
                left: -105px;
            }
            @media (max-width: 1024px) {
                left: -95px;
                width: 1305px;
                top: 990px;
            }
            @media (max-width: $md4+px) {
                left: -433px;
                top: 763px;
            }
            @media (max-width: $md5+px) {
                left: -160px;
                top: 750px;
                width: 1270px;
            }
            @media (max-width: $md6+px) {
                top: 620px;
                left: -170px;
                width: 1280px;
            }
        }
    }
}
