// :root {
//     --bgColor: #3F778A;
//     --color: white;
//     --login-color: black;
//     --login-bg: white;
//     --login-box-shadow: 0px 4px 6px #2F5C6B;
//     --menu-bg: linear-gradient(89.94deg, rgba(84, 222, 253, 0.3) 0.04%, rgba(63, 119, 138, 0) 133.74%);
// }

// .navbar {
//     background-color: var(--bgColor);
//     color: var(--color);
// }

// .dd {
//     color :black;
// }

// .login {
//     position: relative;
// }

// .selected-page {
//     font-weight: 600;
// }

// // .menu-content-content-item:hover {
// //     color: #00D0FF!important;
// // }

// @media screen and (min-width: 1541px) {
//     .navbar {
//         position: relative;
//         display: flex;
//         width: 100%;

//         &-logo {
//             padding: 40px 0 40px 5%;
//             position: relative;
//             z-index: 10;

//             &-icon {
//                 cursor: pointer;
//             }
//         }

//         &-content {
//             display: flex;
//             position: relative;
//             z-index: 10;
//             justify-content: center;
//             gap: 40px;
//             margin-left: auto;
//             padding: 0 1% 0 1%;

//             &-item {
//                 line-height: 30px;
//                 cursor: pointer;

//                 margin: auto 0 auto 0;
//             }
//             &-item:hover {
//                 padding-bottom: 5px;
//                 transition: all .1s;
//             }
//         }

//         &-tools {
//             position: relative;
//             z-index: 10;
//             display: flex;
//             margin-left: auto;
//             padding-right: 5%;

//             &-item {
//                 display: flex;
//                 font-family: Inter;
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 14px;
//                 line-height: 19px;
//                 cursor: pointer;

//                 margin: auto 0 auto 0;
//             }

//             .lang {
//                 margin-right: 30px;
//                 &-arrow {
//                     padding: 7px 0px 7px 2px;
//                 }
//             }

//             .loupe {
//                 margin-right: 30px;
//             }

//             .login {
//                 position: relative;
//                 display: flex;
//                 flex-direction: row;
//                 align-items: flex-start;
//                 padding: 7px 18px;

//                 font-family: 'Arial';
//                 font-style: normal;
//                 font-weight: 400;
//                 font-size: 14px;
//                 line-height: 16px;

//                 background: var(--login-bg);
//                 color: var(--login-color);
//                 border-radius: 100px;
//                 box-shadow: var(--login-box-shadow);
//             }
//         }
//     }
// }

// // @media screen and (min-width: 1025px) and (max-width: 1280px) {
// //     .navbar {
// //         position: relative;
// //         display: flex;
// //         width: 100%;

// //         &-logo {
// //             padding: 50px 0 50px 5%;
// //             position: relative;
// //             z-index: 10;

// //             &-icon {
// //                 cursor: pointer;
// //             }
// //         }

// //         &-content {
// //             display: flex;
// //             position: relative;
// //             z-index: 10;
// //             justify-content: center;
// //             gap: 20px;
// //             margin-left: auto;
// //             padding: 0 1% 0 1%;

// //             &-item {
// //                 line-height: 30px;
// //                 cursor: pointer;

// //                 margin: auto 0 auto 0;
// //             }
// //         }

// //         &-tools {
// //             position: relative;
// //             z-index: 10;
// //             display: flex;
// //             margin-left: auto;
// //             padding-right: 5%;

// //             &-item {
// //                 display: flex;
// //                 font-family: Inter;
// //                 font-style: normal;
// //                 font-weight: 400;
// //                 font-size: 14px;
// //                 line-height: 19px;
// //                 cursor: pointer;

// //                 margin: auto 0 auto 0;
// //             }

// //             .lang {
// //                 margin-right: 30px;
// //                 &-arrow {
// //                     padding: 7px 0px 7px 2px;
// //                 }
// //             }

// //             .loupe {
// //                 margin-right: 30px;
// //             }

// //             .login {
// //                 position: relative;
// //                 display: flex;
// //                 flex-direction: row;
// //                 align-items: flex-start;
// //                 padding: 7px 18px;

// //                 font-family: 'Arial';
// //                 font-style: normal;
// //                 font-weight: 400;
// //                 font-size: 14px;
// //                 line-height: 16px;

// //                 background: var(--login-bg);
// //                 color: var(--login-color);
// //                 border-radius: 100px;
// //                 box-shadow: var(--login-box-shadow);
// //             }
// //         }
// //     }
// // }

// .Dropdown-control {
//     background-color: transparent;
//     border: none;
//     color: #fff;
//     text-transform: uppercase;
//     padding-right: 30px;
// }

// .Dropdown-arrow {
// }

.btn-anim-dark {
    &.cz {
        width: 100px;
    }
}
