@import '../../../styles/var';

.whatFor {
    display: flex;
    justify-content: space-between;
    @media(max-width: $md4+px) {
        display: block;
    }
    .col {
        width: 49%;
        @media(max-width: $md4+px) {
            width: 100%;
        }
    }
    .header {

    }
    .text {
        margin: 20px 0 40px 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #515151;
        @media(max-width: $md2+px) {
            font-size: 16px;
        }
        @media(max-width: $md4+px) {
            margin: 15px 0 30px 0;
        }
        @media(max-width: $md6+px) {
            font-size: 14px;
        }
        .par {
            margin-top: 15px;
            @media(max-width: $md4+px) {
                margin-top: 10px;
            }
        }
    }
    .btn {
        display: inline-flex;
        position: relative;
        color: white;
        background-color: #3F778A;
        padding: 18px 30px;
        border-radius: 100px;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        cursor: pointer;
        @media (max-width: $md6+px) {
            font-size: 16px;
            padding: 12px 22px;
        }
    }
    img {
        margin-left: auto;
        display: flex;
        width: 80%;
        transition: all .5s;
        @media(max-width: $md1+px) {
            width: 100%;
        }
        @media(max-width: $md2+px) {
            width: 80%;
        }
        @media(max-width: $md3+px) {
            width: 100%;
        }
        @media(max-width: $md4+px) {
            width: 60%;
            margin: 40px auto 0 auto;
        }
        @media(max-width: $md6+px) {
            width: 100%;
        }
    }
}