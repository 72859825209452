@import "../../../styles/_var.scss";
.sub {
}
.subBody {
    margin-bottom: 150px;
    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}
.subTitle {
    @media (max-width: $md5+px) {
    }
}
.subSubtitle {
    font-family: "PT Root UI", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    color: #515151;
    max-width: 616px;
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
    }
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
