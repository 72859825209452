@import "../../styles/_var.scss";
@font-face {
    font-family: "Bebas Neue";
    src: url("../../../public/fonts/BebasNeue-Regular.ttf") format("truetype");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

//<Шрифт по умолчанию>==========================================================================================
$fontfamily: "PT Root UI", sans-serif;

//<ПЕРЕМЕННЫЕ>===========================================================================================================

body {
    font-family: $fontfamily;
}
//</ПЕРЕМЕННЫЕ>==============================================================================================================
//<ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================
// * {
//     padding: 0px;
//     margin: 0px;
//     border: 0px;
// }

// *,
// *:before,
// *:after {
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }

// :focus,
// :active {
//     outline: none;
// }

// a:focus,
// a:active {
//     outline: none;
// }

// aside,
// nav,
// footer,
// header,
// section {
//     display: block;
// }

// html,
// body {
//     transition: 1s all linear;
//     height: auto;
//     margin: 0;
//     padding: 0;
//     min-width: $minwidth;
//     position: relative;
//     width: 100%;
//     color: #000;
//     background-color: #fff;
//     font-family: $fontfamily;
// }

// body {
//     font-size: 100%;
//     line-height: 1;
//     font-size: 14px;
//     -ms-text-size-adjust: 100%;
//     -moz-text-size-adjust: 100%;
//     -webkit-text-size-adjust: 100%;

//     &.lock {
//         @media (max-width: 992px) {
//             overflow: hidden;
//         }
//     }
// }

// input,
// button,
// textarea {
//     font-family: $fontfamily;
// }

// input::-ms-clear {
//     display: none;
// }

// button {
//     cursor: pointer;
// }

// button::-moz-focus-inner {
//     padding: 0;
//     border: 0;
// }

// a,
// a:visited {
//     text-decoration: none;
// }

// a:hover {
//     text-decoration: none;
// }

// ul li {
//     list-style: none;
// }

// img {
//     vertical-align: top;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-weight: inherit;
//     font-size: inherit;
// }

.bluebg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #1a5a6f;
    opacity: 0;
    z-index: 10;
    display: block;
    pointer-events: none;
    transition: all 0.3s linear;
    &.active {
        @media (max-width: 1024px) {
            opacity: 0.4;
        }
    }
}

.container {
    max-width: $mw + px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: $md1+px) {
    }

    @media (max-width: $md2+px) {
    }
    @media (max-width: 768px) {
    }
    @media (max-width: $md3+px) {
        max-width: none;
    }
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: calc(100% - 20px);
    height: 100px;
    background-color: #fff;
    @media (max-width: $md3+px) {
        width: 100%;
    }
    &__body {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.headerrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__logo {
        max-width: 136px;
        img {
            width: 100%;
        }
    }
}
.headerrowright {
    display: flex;
    align-items: center;
    &__logbtn {
        margin-left: 30px;
        width: 74px;
        height: 32px;
        background: #3f778a;
        text-align: center;
        line-height: 32px;
        border-radius: 20px;
        transition: all 0.3s linear;
        font-size: 14px;
        color: #ffffff;
        position: relative;
        cursor: pointer;
        @media (max-width: $md2+px) {
            width: 82px;
            height: 42px;
            line-height: 42px;
            margin-left: 10px;
        }
    }
    &__burger {
        position: relative;
        height: 23px;
        width: 32px;
        margin-left: 54px;
        z-index: 10;
        display: none;
        cursor: pointer;
        position: relative;
        @media (max-width: $md2+px) {
            margin-left: 20px;
        }
        @media (max-width: $md6+px) {
            margin-left: 15px !important;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: -10px;
            top: -8px;
            position: absolute;
            width: 50px;
            height: 40px;
        }
        @media (max-width: $md2+px) {
            display: block;
        }
        @media (max-width: $md4+px) {
            margin-left: 24px;
        }
        span {
            pointer-events: none;
            position: absolute;
            width: 32px;
            transition: all 0.3s linear;
            height: 2px;
            background-color: #000;
            &:first-child {
                left: 0;
                top: 0;
                width: 20px;
            }
            &:nth-child(2) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:last-child {
                bottom: 0;
                width: 20px;
                right: 0;
            }
        }
        &.active {
            span {
                &:first-child {
                    left: 0;
                    top: 11px;

                    margin: auto;
                    width: 32px;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
                &:last-child {
                    opacity: 0;
                }
            }
        }
    }
}

.headerrowrightnav {
    display: flex;

    @media (max-width: $md2+px) {
        display: block;
        padding: 100px 0 145px 0;
        background: #ffffff;
    }
    @media (max-width: $md3+px) {
        padding: 50px 0 70px 0;
    }

    &__wrap {
        @media (max-width: $md2+px) {
            position: fixed;
            z-index: 10;
            top: -50px;
            margin-top: 100px;
            right: -50px;
            pointer-events: none;
            width: 502px;
            height: 100%;

            overflow: auto;
            display: block;
            text-align: center;
            opacity: 0;
            transition: all 0.3s linear;

            &.active {
                pointer-events: visible;
                opacity: 1;
                right: 0;
                top: 0;
            }
        }
        @media (max-width: $md3+px) {
            width: 244px;
            padding-bottom: 70px;
            &.active {
                opacity: 1;
                right: 0;
                top: 0px;
            }
        }
    }

    &__item {
        margin-left: 30px;
        font-size: 20px;
        color: #000000;
        display: block;
        cursor: pointer;
        text-decoration: none;

        transition: all 0.3s linear;
        &:hover,
        &.active {
            color: #3f778a;
            font-weight: 700;
        }
        @media (max-width: $md1+px) {
            font-size: 17px;
        }
        @media (max-width: 1050px) {
            font-size: 15px;
        }
        @media (max-width: $md2+px) {
            margin-bottom: 40px;
            font-size: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: 1024px) {
            margin-left: 0;
        }
        @media (max-width: $md3+px) {
            margin-bottom: 40px;
            font-size: 20px;
            margin-left: 0;
        }
    }
}

.headerRowRightLocalization {
    position: relative;
    z-index: 10;
    margin-right: 0px;
    margin-left: 40px;

    @media (max-width: $md5+px) {
        margin: 0 20px 0 10px;
    }
    @media (max-width: $md6+px) {
        margin: 0 5px 0 10px;
    }
}
.headerRowRightLocalizationTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.3s linear;
    color: #21211d;
    @media (max-width: $md1+px) {
        font-size: 14px;
    }
    &.white {
        color: #fff;
        img {
            filter: brightness(0) invert(1);
        }
    }

    &.active {
        img {
            transform: rotate(180deg);
        }
    }
    img {
        transition: all 0.3s linear;
        margin-left: 5px;
    }
}
.headerRowRightLocalizationItems {
    position: absolute;
    left: 2px;
    top: 30px;
    background: #fff;
    color: #000;
    padding: 5px 0;

    p {
        padding: 5px 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    display: none;
    &.active {
        display: block;
    }
}
.headerRowRightSignBtn {
    @media (max-width: $md4+px) {
        z-index: 10;
    }

    & > div {
        width: 145px;
        text-transform: none;
        @media (max-width: $md2+px) {
            width: 100px !important;
        }
    }
    @media (max-width: $md4+px) {
        &.active {
            & > div:after {
                opacity: 0;
            }
        }
    }
}
