@font-face {
    font-family: "Bebas Neue";
    src: url("../../../public/fonts/BebasNeue-Regular.ttf") format("truetype");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../../../public/fonts/PT-Root-UI_Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

//<Шрифт по умолчанию>==========================================================================================
$fontfamily: "PT Root UI", sans-serif;

//<ПЕРЕМЕННЫЕ>===========================================================================================================
$minwidth: 320px;
$mw: 1312;
$md1: $mw + 12;
$md2: 1024;
$md3: 769;
$md4: 480;

body {
    font-family: $fontfamily;
    overflow-x: hidden;
}

.container {
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}

.footer {
    &__body {
        background-color: #3f778a;
        padding: 55px 0 50px 0;
    }
}
.footerrow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: hidden;
    @media (max-width: $md1+px) {
        margin-bottom: 25px;
    }
    @media (max-width: 768px) {
    }
}
.footerrowleft,
.footerrowcenter {
    flex: 0 424px;
    @media (max-width: $md1+px) {
        margin-bottom: 80px;
        flex: 0 1 212px;
    }
    @media (max-width: 480px) {
        flex: 0 1 45%;
    }
    @media (max-width: 410px) {
        flex: 0 1 40%;
    }
    &__logo {
        width: 114px;
        margin-bottom: 20px;
        @media (max-width: $md1+px) {
            margin: 0 0 45px 0;
        }
        img {
            width: 100%;
        }
    }

    &__info,
    a {
        font-size: 16px;
        line-height: 1;
        color: #ffffff;
        display: block;
        margin-bottom: 10px;
        @media (max-width: $md1+px) {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: 480px) {
            font-size: 14px;
        }
    }
}

.footerrowleft,
.footerrowcenter,
.footerrowright {
    @media (max-width: 992px) {
        flex: 0 1 32% !important;
    }
    @media (max-width: 768px) {
        flex: 0 1 250px !important;
    }
}

.footerrowcenter {
    @media (max-width: $md1+px) {
        position: relative;
        left: 20px;
    }
    @media (max-width: 992px) {
        left: 0;
    }
    &__info {
    }
}
.footerrowright {
    flex: 0 1 424px;
    display: flex;
    flex-direction: column;
    &__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 5px;
        color: #ffffff;
        @media (max-width: $md1+px) {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }

    &__subtitle {
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 20px;
        @media (max-width: $md1+px) {
            font-size: 16px;
        }
    }

    &__apps {
        display: flex;
        justify-content: space-between;
        @media (max-width: $md1+px) {
            width: 400px;
        }
        @media (max-width: 480px) {
            width: 100%;
        }
        img {
            cursor: pointer;
            width: 188px;
            @media (max-width: 480px) {
                width: 166px;
            }
            @media (max-width: 385px) {
                width: 150px;
            }
            @media (max-width: 350px) {
                width: 140px;
            }
            @media (max-width: 330px) {
                width: 130px;
            }
        }
    }
}
.footerrowsecond {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerrowsecondleft,
.footerrowsecondcenter,
.footerrowsecondright {
    flex: 0 1 424px;
    @media (max-width: 480px) {
        flex: 0 1 350px !important;
    }
    &__subtitle {
        font-size: 10px;
        line-height: 1.2;
        color: #ffffff;
    }
    &__title {
        font-weight: 700;
        font-size: 10px;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 5px;
    }
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
}

.footerrowsecondleft {
    &__title {
        font-weight: 700;
        font-size: 10px;
        line-height: 1.5;
        color: #ffffff;
    }

    &__subtitle {
        color: #9fbbc4;
        line-height: 1.5;
        font-size: 10px;
    }
}
.footerrowsecondcenter {
    &__title {
        font-weight: 700;
        font-size: 10px;
        line-height: 1.5;
        color: #ffffff;
    }

    &__subtitle {
        color: #9fbbc4;
        line-height: 1.5;
    }
}
.footerrowsecondright {
    @media (max-width: $md1+px) {
        flex: 0 1 100%;
        text-align: center;
        margin-top: 30px;
    }
    &__left {
    }

    &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
        span {
            font-weight: 700;
        }
        margin-bottom: 5px;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        color: #ffffff;
        margin-bottom: 10px;
    }

    &__right {
    }

    &__apps {
        margin-bottom: 34px;
        img {
            width: 188px;
            &:first-child {
                margin-right: 20px;
                @media (max-width: 480px) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
        }
        @media (max-width: 480px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__copyright {
        font-size: 16px;
        line-height: 1.2;
        color: #ffffff;
    }
}
