@import "../../../styles/var";

.toGrow {
    margin: 150px 0;
    height: 634px;
    background-color: #e0eaee;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    @media (max-width: $md3+px) {
        height: 500px;
    }
    @media (max-width: $md5+px) {
        height: auto;
    }
    @media (max-width: $md6+px) {
        margin: 120px 0;
    }
    &:hover .bg {
        height: 105%;
        width: 110%;
        @media (max-width: $md5+px) {
            width: 150%;
        }
    }
    .bg {
        transition: all 1s;
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        border-radius: 20px;
        background-image: url("../../../assets/AboutPage/grow_pentagon.png");
        width: 100%;
        height: 100%;
        background-size: cover;
        @media (max-width: $md5+px) {
            width: 170%;
        }
    }
    .header {
        position: relative;
        z-index: 3;
        width: 973px;
        margin: 0 auto;
        padding-top: 110px;
        text-align: center;
        @media (max-width: $md2+px) {
            width: 800px;
        }
        @media (max-width: $md4+px) {
            width: calc(100% - 60px);
            margin: 0 30px;
        }
        @media (max-width: $md5+px) {
            width: calc(100% - 16px);
            margin: 0 8px;
            padding-top: 60px;
            font-size: 48px;
        }
        @media(max-width: $md6+px) {
            font-size: 42px;
        }
    }
    .buttons {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        margin-top: 70px;
        @media (max-width: $md3+px) {
            margin-top: 40px;
        }
        @media (max-width: $md5+px) {
            display: block;
            width: 100%;
            padding: 0 10px 60px 10px;
            margin: 40px auto 0 auto;
            text-align: center;
        }
        .demo {
            a {
                color: white;
                display: inline-flex;
                padding: 18px 30px;
                position: relative;
                border-radius: 100px;
                background-color: #3f778a;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                @media (max-width: $md5+px) {
                    width: 100% !important;
                    display: block;
                    padding: 18px 15px;
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    padding: 14px 22px;
                }
            }
        }
        .bonus {
            margin-left: 20px;
            @media (max-width: $md5+px) {
                margin-left: 0;
                margin-top: 20px;
                width: 100% !important;
                display: block;
            }
            @media(max-width: $md6+px) {
                margin-top: 15px;
            }
            a {
                color: black;
                display: inline-flex;
                padding: 18px 30px;
                position: relative;
                border-radius: 100px;
                background-color: white;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
                @media (max-width: $md5+px) {
                    width: 100% !important;
                    display: block;
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    padding: 14px 22px;
                }
            }
            .gift {
                display: flex;
                margin: 7px 0 0 30px;
                .text {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    color: #3f778a;
                    margin-right: 9px;
                }
                @media(max-width: $md6+px) {
                    margin: 7px 0 0 0;
                    justify-content: center;
                }
            }
        }
    }
}
