@import "../../../styles/_var.scss";
.love {
    margin: 100px 0 150px 0;
    @media (max-width: $md4+px) {
        background-color: #3f778a;
    }
}
.loveBody {
}
.loveWrapper {
    padding: 114px 76px 141px 76px;
    position: relative;
    background-color: #3f778a;
    position: relative;
    @media (max-width: $md3+px) {
        padding: 114px 36px 141px 36px;
    }
    @media (max-width: $md4+px) {
        padding: 114px 36px 341px 36px;
    }
    @media (max-width: $md5+px) {
        padding: 114px 0px 341px 0px;
        text-align: center;
    }
    @media (max-width: $md6+px) {
        padding: 114px 0px 251px 0px;
    }
    &:after {
        content: "";
        display: "inline-block";
        right: -200px;
        top: 0;
        position: absolute;
        width: 200px;
        height: 100%;

        backdrop-filter: blur(20px);
        @media (max-width: $md4+px) {
            top: unset;
            bottom: -140px;
            height: 140px;
            width: 100%;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}
.loveTitle {
    margin-bottom: 20px;
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 96px;
    line-height: 1.4;

    max-width: 500px;
    @media (max-width: $md1+px) {
        font-size: 90px;
    }
    @media (max-width: $md2+px) {
        font-size: 48px;
    }
    @media (max-width: $md5+px) {
        text-align: left;
    }
    @media (max-width: $md6+px) {
        font-size: 42px;
    }
}
.loveSubtitle {
    color: #c5d6dc;
    max-width: 791px;
    font-family: "PT Root UI", sans-serif;
    font-size: 36px;
    line-height: 1.4;

    @media (max-width: $md2+px) {
        font-size: 24px;
        max-width: 600px;
    }
    @media (max-width: $md5+px) {
        text-align: left;
    }
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
.loveWrapperBall {
    position: absolute;
    right: -110px;
    top: 70px;
    width: 600px;
    @media (max-width: $md1+px) {
        width: 500px;
    }
    @media (max-width: $md3+px) {
        top: 100px;
        max-width: 450px;
    }
    @media (max-width: $md4+px) {
        top: unset;
        bottom: -130px;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media (max-width: $md5+px) {
        max-width: 400px;
    }
    @media (max-width: $md6+px) {
        max-width: 350px;
    }
    img {
        width: 100%;
    }
}
