@import '../../../styles/var';

.benefits {
    margin-top: 150px;
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .header {
        text-align: center;
        text-align: left;
    }
    .cards {
        margin-top: 40px;
        display: grid;
        grid-template: repeat(2,1fr)/repeat(3,1fr);
        gap: 60px 0;
        @media(max-width: $md4+px) {
            grid-template: repeat(3,1fr)/repeat(2,1fr);
        }
        @media(max-width: $md6+px) {
            grid-template: repeat(6,0fr)/repeat(1,1fr);
            gap: 40px 0;
        }
        .card {
            text-align: center;
            &:hover img {
                scale: 1.1;
            }
            &Icon {
                img {
                    transition: all .2s;
                    width: 111px;
                    @media(max-width: $md2+px) {
                        width: 80px;
                    }
                }
            }
            &Text {
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                color: #000000;
                width: 255px;
                margin: 10px auto 0 auto;
                @media(max-width: $md2+px) {
                    font-size: 20px;
                    width: 213px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16px;
                    line-height: 150%;
                    width: 170px;
                }
            }
        }
    }
}