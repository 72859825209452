@import '../../../styles/var';

.ourClients {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    @media(max-width: $md4+px) {
        display: block;
    }
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .left {
        width: 800px;
        @media(max-width: $md1+px) {
            width: 616px;
        }
        @media(max-width: $md2+px) {
            width: 580px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .text {
            margin-top: 20px;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #515151;
            @media(max-width: $md2+px) {
                font-size: 16px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                margin-top: 15px;
            }
        }
    }
    .right {
        img {
            margin-right: 100px;
            @media(max-width: $md1+px) {
                margin-right: 0;
            }
            @media(max-width: $md2+px) {
                width: 400px;
            }
            @media(max-width: $md4+px) {
                display: flex;
                margin: 40px auto 0 auto;
            }
            @media(max-width: $md6+px) {
                width: 100%;
                margin-top: 30px;
            }
        }
    }
}