@import '../../../styles/var';

.sources {
    margin-top: 150px;
    @media(max-width: $md6+px) {
        margin-top: 120px;
    }
    .header {
    
    }
    .cards {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin-top: 40px;
        @media(max-width: $md3+px) {
            display: grid;
            gap: 0 5%;
            grid-template: repeat(1,0fr)/repeat(3,1fr);
            overflow: scroll;
            overflow-y: hidden;
            padding-bottom: 30px;
        }
        &::-webkit-scrollbar {
            height: 4px;
            background-color: #3F778A;
        }
        &::-webkit-scrollbar-track {
            background-color: white;
        }

        &::-webkit-scrollbar-thumb {
            background: #3F778A;
        }
        .card {
            &:nth-child(1) .cardBg {
                background-image: url('../../../assets/AnalyticsPage/sources_01.png');
            }
            &:nth-child(2) .cardBg {
                background-image: url('../../../assets/AnalyticsPage/sources_02.png');
                height: 115%;
                @media(max-width: $md1+px) {
                    height: 100%;
                }
                &:hover {
                    height: 120%;
                    @media(max-width: $md1+px) {
                        height: 105%;
                    }
                }

            }
            &:nth-child(3) .cardBg {
                background-image: url('../../../assets/AnalyticsPage/sources_03.png');
            }
            overflow: hidden;
            position: relative;
            cursor: pointer;
            width: 500px;
            height: 634px;
            border-radius: 20px;
            padding: 0px 30px;
            background: #E0EAEE;
            @media(max-width: $md1+px) {
                width: 424px;
            }
            @media(max-width: $md2+px) {
                height: 500px;
            }
            @media(max-width: $md5+px) {
                width: calc(500px * 0.55);
                height: calc(634px * 0.55);
                padding: 0 20px;
            }
            &:hover {
                .cardBg {
                    height: 115%;
                    @media(max-width: $md1+px) {
                        height: 105%;
                    }
                }
                .cardHeader {
                    margin-top: 40px;
                }
            }
            &Bg {
                transition: all .5s;
                background-size: cover;
                border-radius: 20px;
                width: 100%;
                height: 110%;
                position: absolute;
                bottom: 0;
                left: 0;
                @media(max-width: $md1+px) {
                    height: 100%;
                }
            }
            &Header {
                transition: all .5s;
                margin-top: 60px;
                font-weight: 700;
                font-size: 36px;
                line-height: 45px;
                color: #3F778A;
                @media(max-width: $md2+px) {
                    font-size: 24px;
                    line-height: normal;
                }
                @media(max-width: $md5+px) {
                    margin-top: 30px;
                }
                @media(max-width: $md6+px) {
                    font-size: 20px;
                }
            }
            &Text {
                margin-top: 20px;
                font-weight: 400;
                font-size: 20px;
                line-height: 150%;
                color: #515151;
                @media(max-width: $md2+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
        }
    }
}