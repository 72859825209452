@font-face {
    font-family: "BebasNeue";
    src: url("../fonts/BebasNeue-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");

@font-face {
    font-family: "PT Root UI";
    src: url("../fonts/PT-Root-UI_Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../fonts/PT-Root-UI_Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "PT Root UI";
    src: url("../fonts/PT-Root-UI_Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
