@import "../../../styles/var";

.whoWeAre {
    margin-top: 50px;
    margin-top: 200px;
    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
    .header {
        font-family: "Rubik", sans-serif;
        font-weight: 400;
        font-size: 96px;
        line-height: 74px;
        color: #3f778a;
        @media (max-width: $md2+px) {
            font-size: 76px;
        }
        @media (max-width: $md6+px) {
            font-size: 48px;
        }
    }
    .content {
        margin-top: 20px;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #515151;
        display: grid;
        grid-template: repeat(1, 1fr) / repeat(2, 1fr);
        gap: 50px;
        @media (max-width: $md2+px) {
            font-size: 16px;
        }
        @media (max-width: $md4+px) {
            display: block;
        }
        @media (max-width: $md6+px) {
            font-size: 14px;
            margin-top: 5px;
        }
        .left {
        }
        .right {
            .text {
                @media (max-width: $md4+px) {
                    margin-top: 10px;
                }
            }
            .rightHeader {
                color: #3f778a;
                @media (max-width: $md4+px) {
                    margin-top: 30px;
                }
            }
            .rightText {
                margin-top: 10px;
                @media (max-width: $md2+px) {
                    margin-top: 0;
                }
                @media (max-width: $md4+px) {
                    margin-top: 15px;
                }
                @media (max-width: $md5+px) {
                    margin-top: 10px;
                }
            }
        }
    }
}
