@import "../../../styles/_var.scss";
.winner {
}
.winnerBody {
    margin: 0 0 150px 0;
    @media (max-width: $md4+px) {
        margin: 0 0 120px 0;
    }
}
.winnerTitle {
    text-align: center;
    span {
        display: block;
        @media (max-width: $md6+px) {
            display: inline;
        }
    }
}
.winnerSubtitle {
    font-family: "PT Root UI", sans-serif;
    font-size: 20px;
    line-height: 1.5;
    max-width: 616px;
    text-align: center;
    color: #515151;
    margin: 0 auto 40px auto;
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.winnerRow {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1312px;
    margin: 0 auto;
    @media (max-width: $md5+px) {
        display: block;
    }
}
.winnerHide {
    @media (max-width: $md5+px) {
        display: none;
    }
}
.winnerRowColumn {
    cursor: pointer;
    transition: all 0.3s linear;
    @media (max-width: $md2+px) {
        img {
            width: 100%;
        }
        flex: 0 1 270px;
        &:nth-child(2) {
            flex: 0 1 350px;
        }
    }
    @media (max-width: $md5+px) {
        width: 300px;
        margin: 0 auto;
    }

    &.blur {
        filter: blur(4px);
    }
    &:hover {
        transform: scale(1.1);
    }
}

.winnerVisible {
    display: none;
    @media (max-width: $md5+px) {
        display: block;
    }
}
