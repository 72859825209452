@import "../../../styles/_var.scss";
.payment {
    padding: 100px 0 75px 0;
    @media (max-width: $md4+px) {
        padding: 0px 0 0px 0;
    }
}
.payment__title {
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: $md4+px) {
        margin-bottom: 25px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 15px;
    }
}
@media (max-width: 992px) {
    .payment__title {
    }
}
.payment__number {
    font-size: 32px;
    color: #9b9b9b;
    max-width: 850px;
    margin: 0 auto 30px auto;
}
@media (max-width: 992px) {
    .payment__number {
        display: none;
    }
}
.payment__number span {
    color: #000;
}

.paymentrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 850px;
    margin: 0 auto;
}
@media (max-width: 992px) {
    .paymentrow {
        display: none;
    }
}
.paymentrow__left {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 45%;
    flex: 0 1 45%;
}
.paymentrow__left p {
    font-size: 48px;
    color: #767676;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
}
.paymentrow__left p:hover {
    color: #000;
}
.paymentrow__left p:hover:after {
    display: block;
}
.paymentrow__left p:after {
    content: "";
    display: "inline-block";
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    width: 36px;
    height: 24px;
    // background: url("../img/4/4.svg") no-repeat;
    background: url("../../../../public/img/4/4.svg") no-repeat;
    display: none;
}
.paymentrow__center {
    display: none;
}
.paymentrow__img {
    display: none;
}
.paymentrow__img img {
    width: 100%;
}
.paymentrow__img.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.paymentrow__img.visa {
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.paymentrow__img.visa img {
    width: 410px;
    height: 126px;
}
.paymentrow__img.mastercard {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}
.paymentrow__img.mastercard img {
    width: 306px;
    height: 183px;
}
.paymentrow__img.maestro {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
}
.paymentrow__img.maestro img {
    width: 308px;
    height: 183px;
}

.paymentmobrow {
    display: flex;
    justify-content: space-between;
    max-width: 550px;
    margin: 0 auto;
}
.paymentmobrow__wrap {
    width: 100%;
    overflow: auto;
    padding-bottom: 20px;
}
@media (max-width: 992px) {
    .paymentmobrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 60px;
    }
}
@media (max-width: 768px) {
    .paymentmobrow {
        padding: 0 20px;
    }
}
@media (max-width: 992px) {
    .paymentmobrow__column {
        margin: 0 20px !important;
    }
}
@media (max-width: 600px) {
    .paymentmobrow {
        margin: 0 auto !important;
        display: flex;
        justify-content: space-between;
        max-width: 500px;
        padding: 0 20px;
        text-align: center;
    }
    .paymentmobrow__column img {
        max-width: 100% !important;
        height: auto !important;
        margin: 0 auto !important;
        text-align: center;
    }
}
.paymentmobrow__column {
}
.payment-mobrow__column:last-child {
    margin-right: 0;
}
.paymentmobrow__column.visa {
    -ms-flex-item-align: start;
    align-self: flex-start;
}
.paymentmobrow__column.visa img {
    width: 146px;
    height: 45px;
}
.paymentmobrow__column.mastercard {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}
.paymentmobrow__column.mastercard img {
    width: 75px;
    height: 45px;
}
.paymentmobrow__column.maestro {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
}
.paymentmobrow__column.maestro img {
    width: 76px;
    height: 45px;
}
