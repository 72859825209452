@import "../../../styles/_var.scss";
.account {
    position: relative;
    z-index: 10;
}
.tabsTitle {
}
.account__body {
    margin: 0 auto;
    padding: 25px 0 150px 0;
    @media (max-width: $md4+px) {
        padding: 25px 0 120px 0;
    }
}
.account__body > .container {
    overflow: auto;
}
.account__disclaimer {
    font-size: 14px;
    line-height: 1.1;
    color: #000000;
    text-align: center;
    margin-top: 10px !important;
}

.accounttabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 50px;
}
.accounttabs__item {
    font-size: 36px;
    line-height: 1.2;
    color: #767676;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 49.7%;
    flex: 0 1 49.7%;
    position: relative;

    cursor: pointer;
}
@media (max-width: 768px) {
    .accounttabs__item {
        font-size: 28px;
    }
}
@media (max-width: 550px) {
    .accounttabs__item {
        font-size: 24px;
    }
}
@media (max-width: 480px) {
    .accounttabs__item {
        font-size: 20px;
    }
}
.accounttabs__item:after {
    content: "";
    display: "inline-block";
    left: 0;
    bottom: -15px;
    position: absolute;
    width: 100%;
    height: 10px;
    background-color: #c0c0c0;
}
.accounttabs__item.active {
    color: #000000;
}
.accounttabs__item.active:after {
    background-color: #3f778a;
}

.accountreal__wrap,
.accountdemo__wrap {
    display: none;
    margin: 0 auto;
    @media (max-width: $md1+px) {
        width: 1300px;
    }
}
@media (max-width: 1324px) {
    .accountreal__wrap,
    .accountdemo__wrap {
        width: 1310px;
    }
}
@media (max-width: 768px) {
    .accountreal__wrap,
    .accountdemo__wrap {
        width: 1001px;
    }
}
.accountreal__wrap.active,
.accountdemo__wrap.active {
    display: block;
}

.accountrealrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountrealrow__column {
    background-color: #fff6e8;
    width: 536px;
    height: 724px;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column {
        height: 648px;
        width: 333px;
        position: relative;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(1) .accountrealrowfitem {
        background: url("../../../../public/img/3/mobile/column1.png") 0 0 / contain no-repeat;
    }
}
.accountrealrow__column:nth-child(1) .accountrealrowsitem {
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(1) .accountrealrowsitem {
        background: url("../../../../public/img/3/mobile/column1_h.png") 0 0/0 0 no-repeat;
    }
}
.accountrealrow__column:nth-child(2) {
    background-color: #ecffed;
}
.accountrealrow__column:nth-child(2) .accountrealrowfitemtop__left {
    color: #009c07;
}
.accountrealrow__column:nth-child(2) .accountrealrowfitem {
    background: url("../../../../public/img/3/column2.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(2) .accountrealrowfitem {
        background: url("../../../../public/img/3/mobile/column2.png") 0 0 / contain no-repeat;
    }
}
.accountrealrow__column:nth-child(2) .accountrealrowsitem {
    background: url("../../../../public/img/3/column2_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(2) .accountrealrowsitem {
        background: url("../../../../public/img/3/mobile/column2_h.png") 0 0 / contain no-repeat;
    }
}
.accountrealrow__column:nth-child(3) {
    background-color: #e5eeff;
}
.accountrealrow__column:nth-child(3) .accountrealrowfitemtop__left {
    color: #004bdb;
}
.accountrealrow__column:nth-child(3) .accountrealrowfitem__update,
.accountrealrow__column:nth-child(3) .accountrealrowsitem__update {
    opacity: 0;
}
.accountrealrow__column:nth-child(3) .accountrealrowfitem {
    background: url("../../../../public/img/3/column3.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(3) .accountrealrowfitem {
        background: url("../../../../public/img/3/mobile/column3.png") 0 0 / contain no-repeat;
    }
}
.accountrealrow__column:nth-child(3) .accountrealrowsitem {
    background: url("../../../../public/img/3/column3_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountrealrow__column:nth-child(3) .accountrealrowsitem {
        background: url("../../../../public/img/3/mobile/column3_h.png") 0 0 / contain no-repeat;
    }
}

.accountrealrowfitem {
    background: #fff6e8;
    width: 536px;
    height: 100%;
    padding: 30px;
    background: url("../../../../public/img/3/column1.png") 0 0 / cover no-repeat;
    position: relative;
    // -webkit-transition: all 0.3s linear;
    // transition: all 0.3s linear;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountrealrowfitem {
        width: 333px;
        padding: 30px 5px;
        position: absolute;
        left: 0;
        top: 0;
    }
}
.accountrealrowfitem__bottom {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 30px;
}
@media (max-width: 768px) {
    .accountrealrowfitem__bottom {
        padding: 0 5px;
    }
}
.accountrealrowfitem__subtop {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #000000;
    height: 287px;
}
.accountrealrowfitem__update {
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    color: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}
.accountrealrowfitem__update img {
    width: 16px;
    margin-left: 7px;
}
.accountrealrowfitem__btn {
    display: block;
    cursor: pointer;
    width: 273px;
    height: 47px;
    border: 1px solid #000000;
    border-radius: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 47px;
    font-size: 20px;
    color: #000000;
    margin-top: 20px !important;
}

.accountrealrowfitemtop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountrealrowfitemtop__left {
    font-size: 48px;
    color: #d76700;
    font-weight: 700;
    margin-bottom: 10px;
}
.accountrealrowfiteminfo,
.accountrealrowsiteminfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 25px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
@media (max-width: 768px) {
    .accountrealrowfiteminfo,
    .accountrealrowsiteminfo {
        margin-bottom: 10px;
    }
}
.accountrealrowfiteminfo__left,
.accountrealrowsiteminfo__left {
    font-size: 14px;
    line-height: 1.1;
    color: #515151;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 35%;
    flex: 0 1 35%;
}
.accountrealrowfiteminfo__right,
.accountrealrowsiteminfo__right {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 58%;
    flex: 0 1 58%;
    font-size: 14px;
    line-height: 1.1;
    color: #000;
    @media (max-width: $md5+px) {
        font-size: 12px;
    }
}
.accountrealrowfiteminfo__right span,
.accountrealrowsiteminfo__right span {
    font-size: 36px;
}
@media (max-width: 768px) {
    .accountrealrowfiteminfo__right span,
    .accountrealrowsiteminfo__right span {
        font-size: 28px;
    }
}

.accountrealrowsitem {
    display: none;
    width: 536px;
    // -webkit-transition: all 0.3s linear;
    // transition: all 0.3s linear;
    height: 100%;
    padding: 30px;
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
    position: relative;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountrealrowsitem {
        width: 333px;
        padding: 30px 5px;
        position: absolute;
        right: -100%;
        display: block;
        top: 0;
    }
}
.accountrealrowsitem__bottom {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 30px;
}
@media (max-width: 768px) {
    .accountrealrowsitem__bottom {
        padding: 0 5px;
    }
}
.accountrealrowsitem__subtop {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #fff;
}
.accountrealrowsitem__update {
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}
.accountrealrowsitem__update img {
    width: 16px;
    margin-left: 7px;
}
.accountrealrowsitem__btn {
    display: block;
    cursor: pointer;
    width: 273px;
    height: 47px;
    border: 1px solid #fff;
    border-radius: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 47px;
    font-size: 20px;
    color: #000000;
    background: #ffffff;
    -webkit-box-shadow: 0px 4px 6px #2f5c6b;
    box-shadow: 0px 4px 6px #2f5c6b;
    margin-top: 20px !important;
}

.accountrealrowsitemtop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountrealrowsitemtop__left {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
}
.accountrealrowsiteminfo {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.accountrealrowsiteminfo__left {
    color: #dbdbdb;
}
.accountrealrowsiteminfo__right {
    color: #fff;
}

.accountrealrowsiteminfosubrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5px;
}
.accountrealrowsiteminfosubrow__number {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 14%;
    flex: 0 1 14%;
    text-align: right;
    margin-right: 10px;
}
.accountrealrowsiteminfobotrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountrealrowsiteminfobotrow__column {
    margin-bottom: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33%;
    flex: 0 1 33%;
}

@media (min-width: 769px) {
    .accountrealrow__column:hover {
        background-color: #3e778a;
    }
    .accountrealrow__column:hover .accountrealrowfitem {
        display: none;
    }
    .accountrealrow__column:hover .accountrealrowsitem {
        display: block;
    }
}

@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(1) {
        background-color: #3e778a;
        position: relative;
    }
    .accountrealrow__column.active:nth-child(1):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column1_h.png") 0 0 / contain no-repeat;
    }
    .accountrealrow__column.active:nth-child(1) .accountrealrowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountrealrow__column.active:nth-child(1) .accountrealrowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(1) .accountrealrowfitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(1) .accountrealrowsitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(2) {
        background-color: #3e778a;
        position: relative;
    }
    .accountrealrow__column.active:nth-child(2):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column2_h.png") 0 0 / contain no-repeat;
    }
    .accountrealrow__column.active:nth-child(2) .accountrealrowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountrealrow__column.active:nth-child(2) .accountrealrowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(2) .accountrealrowfitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(2) .accountrealrowsitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(3) {
        background-color: #3e778a;
        position: relative;
    }
    .accountrealrow__column.active:nth-child(3):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column3_h.png") 0 0 / contain no-repeat;
    }
    .accountrealrow__column.active:nth-child(3) .accountrealrowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountrealrow__column.active:nth-child(3) .accountrealrowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(3) .accountrealrowfitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountrealrow__column.active:nth-child(3) .accountrealrowsitem {
        background: none;
    }
}

.accountdemorow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountdemorow__column {
    background-color: #3f778a;
    width: 536px;
    height: 724px;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column {
        width: 333px;
        height: 648px;
        position: relative;
    }
}
.accountdemorow__column:nth-child(1) .accountdemorowfitem {
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(1) .accountdemorowfitem {
        background: url("../../../../public/img/3/mobile/column1_h.png") 0 0 / contain no-repeat;
    }
}
.accountdemorow__column:nth-child(1) .accountdemorowsitem {
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(1) .accountdemorowsitem {
        background: url("../../../../public/img/3/mobile/column1_h.png") 0 0 / contain no-repeat;
    }
}
.accountdemorow__column:nth-child(2) {
    background-color: #3f778a;
}
.accountdemorow__column:nth-child(2) .accountdemorowfitemtop__left {
    color: #fff;
}
.accountdemorow__column:nth-child(2) .accountdemorowfitem {
    background: url("../../../../public/img/3/column2_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(2) .accountdemorowfitem {
        background: url("../../../../public/img/3/mobile/column2_h.png") 0 0 / contain no-repeat;
    }
}
.accountdemorow__column:nth-child(2) .accountdemorowsitem {
    background: url("../../../../public/img/3/column2_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(2) .accountdemorowsitem {
        background: url("../../../../public/img/3/mobile/column2_h.png") 0 0 / contain no-repeat;
    }
}
.accountdemorow__column:nth-child(3) {
    background-color: #3f778a;
}
.accountdemorow__column:nth-child(3) .accountdemorowfitemtop__left {
    color: #fff;
}
.accountdemorow__column:nth-child(3) .accountdemorowfitem__update,
.accountdemorow__column:nth-child(3) .accountdemorowsitem__update {
    opacity: 0;
}
.accountdemorow__column:nth-child(3) .accountdemorowfitem {
    background: url("../../../../public/img/3/column3_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(3) .accountdemorowfitem {
        background: url("../../../../public/img/3/mobile/column3_h.png") 0 0 / contain no-repeat;
    }
}
.accountdemorow__column:nth-child(3) .accountdemorowsitem {
    background: url("../../../../public/img/3/column3_h.png") 0 0 / cover no-repeat;
}
@media (max-width: 768px) {
    .accountdemorow__column:nth-child(3) .accountdemorowsitem {
        background: url("../../../../public/img/3/mobile/column3_h.png") 0 0 / contain no-repeat;
    }
}

.accountdemorowfitem {
    width: 536px;
    height: 100%;
    padding: 30px;
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
    position: relative;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountdemorowfitem {
        width: 333px;
        padding: 30px 5px;
        // -webkit-transition: all 0.3s linear;
        // transition: all 0.3s linear;
        position: absolute;
        left: 0;
        top: 0;
    }
}
.accountdemorowfitem__bottom {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 30px;
}
@media (max-width: 768px) {
    .accountdemorowfitem__bottom {
        padding: 0 5px;
    }
}
.accountdemorowfitem__subtop {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #fff;
    height: 287px;
}
.accountdemorowfitem__update {
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}
.accountdemorowfitem__update img {
    width: 16px;
    margin-left: 7px;
}
.accountdemorowfitem__btn {
    display: block;
    cursor: pointer;
    width: 273px;
    height: 47px;
    border: 1px solid #fff;
    border-radius: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 47px;
    font-size: 20px;
    color: #fff;
    margin-top: 20px !important;
}

.accountdemorowfitemtop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountdemorowfitemtop__left {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
}
.accountdemorowfiteminfo,
.accountdemorowsiteminfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 25px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
@media (max-width: 768px) {
    .accountdemorowfiteminfo,
    .accountdemorowsiteminfo {
        margin-bottom: 10px;
    }
}
.accountdemorowfiteminfo__left,
.accountdemorowsiteminfo__left {
    font-size: 14px;
    line-height: 1.1;
    color: #dbdbdb;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 35%;
    flex: 0 1 35%;
}
.accountdemorowfiteminfo__right,
.accountdemorowsiteminfo__right {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 58%;
    flex: 0 1 58%;
    font-size: 14px;
    line-height: 1.1;
    color: #fff;
    @media (max-width: $md5+px) {
        font-size: 12px;
    }
}
.accountdemorowfiteminfo__right span,
.accountdemorowsiteminfo__right span {
    font-size: 36px;
}
@media (max-width: 768px) {
    .accountdemorowfiteminfo__right span,
    .accountdemorowsiteminfo__right span {
        font-size: 28px;
    }
}

.accountdemorowsitem {
    display: none;
    width: 536px;
    height: 100%;
    padding: 30px;
    background: url("../../../../public/img/3/column1_h.png") 0 0 / cover no-repeat;
    position: relative;
    // -webkit-transition: all 0.3s linear;
    // transition: all 0.3s linear;
    @media (max-width: $md1+px) {
        width: 436px;
    }
}
@media (max-width: 768px) {
    .accountdemorowsitem {
        width: 333px;
        padding: 30px 5px;
        display: block;
        right: -100%;
        top: 0;
    }
}
.accountdemorowsitem__bottom {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 30px;
}
@media (max-width: 768px) {
    .accountdemorowsitem__bottom {
        padding: 0 5px;
    }
}
.accountdemorowsitem__subtop {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #000;
}
.accountdemorowsitem__update {
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
}
.accountdemorowsitem__update img {
    width: 16px;
    margin-left: 7px;
}
.accountdemorowsitem__btn {
    display: block;
    cursor: pointer;
    width: 273px;
    height: 47px;
    border: 1px solid #fff;
    border-radius: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 47px;
    font-size: 20px;
    color: #fff;
    background: #3f778a;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    margin-top: 20px !important;
}

.accountdemorowsitemtop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountdemorowsitemtop__left {
    font-size: 48px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;
}
.accountdemorowsiteminfo {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.accountdemorowsiteminfo__left {
    color: #515151;
}
.accountdemorowsiteminfo__right {
    color: #000;
}

.accountdemorowsiteminfosubrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 5px;
}
.accountdemorowsiteminfosubrow__number {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 14%;
    flex: 0 1 14%;
    text-align: right;
    margin-right: 10px;
}
.accountdemorowsiteminfobotrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.accountdemorowsiteminfobotrow__column {
    margin-bottom: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33%;
    flex: 0 1 33%;
}

@media (min-width: 769px) {
    .accountdemorow__column:hover {
        background-color: #fff;
        -webkit-filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
    }
    .accountdemorow__column:hover .accountdemorowfitem {
        display: none;
    }
    .accountdemorow__column:hover .accountdemorowsitem {
        display: block;
    }
}

@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(1) {
        background-color: #fff !important;
        -webkit-filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        position: relative;
    }
    .accountdemorow__column.active:nth-child(1):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column1_h.png") 0 0 / contain no-repeat;
    }
    .accountdemorow__column.active:nth-child(1) .accountdemorowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountdemorow__column.active:nth-child(1) .accountdemorowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(1) .accountdemorowfitem {
        background: none !important;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(1) .accountdemorowsitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(2) {
        background-color: #fff;
        -webkit-filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        position: relative;
    }
    .accountdemorow__column.active:nth-child(2):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column2_h.png") 0 0 / contain no-repeat;
    }
    .accountdemorow__column.active:nth-child(2) .accountdemorowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountdemorow__column.active:nth-child(2) .accountdemorowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(2) .accountdemorowfitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(2) .accountdemorowsitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(3) {
        background-color: #fff;
        -webkit-filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
        position: relative;
    }
    .accountdemorow__column.active:nth-child(3):after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../public/img/3/mobile/column3_h.png") 0 0 / contain no-repeat;
    }
    .accountdemorow__column.active:nth-child(3) .accountdemorowfitem {
        left: -100%;
        opacity: 0;
    }
    .accountdemorow__column.active:nth-child(3) .accountdemorowsitem {
        right: 0px;
        z-index: 10;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(3) .accountdemorowfitem {
        background: none;
    }
}
@media (max-width: 768px) {
    .accountdemorow__column.active:nth-child(3) .accountdemorowsitem {
        background: none;
    }
}

.learnmore {
    margin-top: 20px;
    margin-bottom: 0 !important;
    display: none !important;
}
.learnmore img {
    position: relative;
    top: 2px;
}
@media (max-width: 768px) {
    .learnmore {
        display: block !important;
        opacity: 1 !important;
    }
}
.back_b {
    color: #000;
}
.back img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 10px;
}
