@import "../../../styles/_var.scss";
.start {
    width: 1512px;
    margin: 0 auto;
    position: relative;
}
@media (max-width: 1350px) {
    .start {
        width: 100%;
    }
}
.start__body {
    padding: 125px 0 140px 0;
    position: relative;
    @media (max-width: $md4+px) {
        padding: 120px 0 120px 0;
    }
}
.start__firstbg,
.start__secondbg {
    position: absolute;
    z-index: 1;
}
.start__firstbg img,
.start__secondbg img {
    width: 100%;
}
.start__firstbg {
    height: 210px;
    width: 250px;
    top: 125px;
    left: 350px;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
}
@media (max-width: 1200px) {
    .start__firstbg {
        left: 800px;
        top: 70px;
    }
}
@media (max-width: 1040px) {
    .start__firstbg {
        left: 700px;
    }
}
.start__firstbg.active {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}
@media (max-width: 930px) {
    .start__firstbg {
        display: none;
    }
}
.start__secondbg {
    height: 399px;
    width: 472px;
    top: 370px;
    z-index: 4;
    left: -145px;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
}
@media (max-width: 1200px) {
    .start__secondbg {
        left: 650px;
        top: 450px;
    }
}
@media (max-width: 1040px) {
    .start__secondbg {
        left: 550px;
    }
}
@media (max-width: 930px) {
    .start__secondbg {
        display: none;
    }
}
.start__secondbg.active {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.startrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 2;
}
@media (max-width: 1200px) {
    .startrow {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.startrow__left {
    -webkit-box-flex: 1;
    font-size: 96px !important;
    position: relative;
    z-index: 3;
    flex: 1 0 535px;

    span {
        display: block;
    }
}
.wrap1 {
    position: relative;
    z-index: 5;
}
@media (max-width: 1200px) {
    .startrow__left {
        font-size: 48px !important;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 535px;
        flex: 0 1 535px;
        margin-bottom: 60px;
    }
    .startrow__left span {
        display: block;
    }
}
@media (max-width: 480px) {
    .startrow__left {
        font-size: 42px !important;
    }
}
@media (max-width: $md6+px) {
    .startrow__left {
        font-size: 42px;

        margin-bottom: 45px;
    }
}

@media (max-width: 1200px) {
    .startrowright {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 100%;
        flex: 0 1 100%;
    }
}
.startrowright__column {
    margin-bottom: 65px;
}
@media (max-width: 1200px) {
    .startrowright__column {
        max-width: 535px;
    }
}
@media (max-width: 650px) {
    .startrowright__column {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
.startrowright__column:last-child {
    margin-bottom: 0;
}
.startrowright__number {
    color: #cccccc;
    font-family: "Rubik", sans-serif;
    font-size: 48px;
}
@media (max-width: 650px) {
    .startrowright__number {
        margin-right: 20px;
    }
}
.startrowright__text {
    font-size: 32px;
    line-height: 1.3;
    color: #000000;
    @media (max-width: $md2+px) {
        font-size: 25px;
    }
    @media (max-width: $md6+px) {
        font-size: 16px;
        max-width: 291px;
    }
}
