@import "../../../styles/_var.scss";
.trade {
    margin-top: 100px;
}
.tradeBody {
    padding-bottom: 200px;

    @media (max-width: $md4+px) {
        padding-bottom: 190px;
    }
    &.active {
        .tradeBall {
            transform: scale(1.05) rotate(-4deg) translate(0, -10px);
        }
    }
}
.tradeWrapper {
    padding: 114px 76px 154px 76px;
    background-color: #3f778a;
    position: relative;
    @media (max-width: $md4+px) {
        margin: 0 -10px;
        padding: 114px 36px 254px 36px;
    }
    @media (max-width: $md5+px) {
        padding: 80px 36px 254px 36px;
    }
    &:before {
        content: "";
        display: "inline-block";
        right: -300px;
        bottom: -200px;
        position: absolute;
        width: 100%;
        height: 200px;
        z-index: 10;
        backdrop-filter: blur(20px);
        @media (max-width: $md4+px) {
            height: 190px;
            bottom: -190px;
            right: 0;
        }
    }
}
.tradeTitle {
    font-family: "Rubik", sans-serif;

    font-size: 96px;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    z-index: 3;
    @media (max-width: $md1+px) {
        font-size: 85px;
    }
    @media (max-width: $md2+px) {
        font-size: 75px;
    }
    @media (max-width: $md3+px) {
        font-size: 65px;
    }
    @media (max-width: $md4+px) {
        font-size: 55px;
    }

    @media (max-width: $md6+px) {
    }
}
.tradeSubtitle {
    color: #c5d6dc;
    font-family: "PT Root UI", sans-serif;
    font-size: 36px;
    line-height: 1.3;
    max-width: 868px;
    margin-bottom: 60px;
    position: relative;
    z-index: 3;
    @media (max-width: $md1+px) {
        max-width: 650px;
        font-size: 32px;
    }
    @media (max-width: $md5+px) {
        margin: 0 0 30px 0;
        font-size: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 22px;
    }
}
.tradeStart {
    width: 260px;
    height: 61px;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "PT Root UI", sans-serif;
    font-style: normal;
    font-size: 17px;
    color: #000000;

    transition: all 0.3s linear;
    cursor: pointer;
    @media (max-width: $md5+px) {
    }
    @media (max-width: $md6+px) {
        width: 200px;
        height: 50px;
        line-height: 50px;
    }
    img {
        margin-left: 10px;
    }
}
.tradeBall {
    position: absolute;
    right: 20px;
    bottom: -200px;
    width: 600px;
    transition: all 0.3s linear;
    @media (max-width: $md2+px) {
        width: 450px;
        bottom: -170px;
    }
    @media (max-width: $md4+px) {
        width: 350px;
        bottom: -100px;
    }
    @media (max-width: $md5+px) {
        right: 0;
        left: 0;
        width: 300px;
        margin: auto;
        bottom: -100px;
    }
    img {
        width: 100%;
    }
}
