@import "../../../styles/_var.scss";
.about {
    position: relative;
    z-index: 6;
    top: 150px;
    @media (max-width: $md1+px) {
        top: 100px;
    }
    @media (max-width: $md2+px) {
        top: 50px;
    }
}
.about__body {
    padding: 150px 0 120px 0;
    @media (max-width: $md4+px) {
        padding: 120px 0 150px 0;
    }
}
.about__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    top: -80px;
}
@media (max-width: 1324px) {
    .about__title {
        margin-bottom: 60px;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 100%;
        flex: 0 1 100%;
    }
}
@media (max-width: 1024px) {
    .about__title {
        margin-bottom: 30px;
    }
}

.aboutright {
    max-width: 755px;
    position: relative;
    left: -30px;
}
@media (max-width: 1324px) {
    .aboutright {
        left: 0;
        max-width: 558px;
    }
}
.aboutright__text {
    font-size: 24px;
    line-height: 1.2;
    color: #515151;
    margin-bottom: 20px;
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.aboutright__btn {
    margin-top: 40px;

    height: 61px;
    border-radius: 100px;
    text-align: center;
    line-height: 61px;
    font-size: 20px;
    color: #fff;
    background-color: #3f778a;
    cursor: pointer;
    transition: all 0.3s linear;
    position: relative;

    display: inline-block;
    padding: 0px 30px;
    @media (max-width: $md6+px) {
        font-size: 16px;
        height: 49px;
        padding: 0px 22px;
        line-height: 49px;
    }
}
