@import "../../../styles/var";

.banner {
    margin-top: 100px;
    background-color: white;
    position: relative;
    overflow: hidden;
    .cont {
        position: relative;
    }
    .content {
        position: relative;
        z-index: 2;
        background-color: #3f778a;
        padding: 83px 60px 0 60px;
        @media (min-width: $md3+px) {
            &:hover ~ img {
                top: 150px;
                transform: rotate(-4deg);
                @media (max-width: $md1+px) {
                    top: 280px;
                }
                @media (max-width: $md2+px) {
                    top: 220px;
                }
            }
        }
        @media (max-width: $md5+px) {
            padding: 60px 20px 0 20px;
        }
        .header {
            position: relative;
            z-index: 999;
            text-transform: uppercase;
            color: white;
            font-family: "Rubik", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 96px;
            max-width: 1080px;
            @media (max-width: $md2+px) {
                font-size: 64px;
                line-height: normal;
            }
            @media (max-width: $md5+px) {
                font-size: 50px;
            }
            @media (max-width: $md6+px) {
                font-size: 48px;
            }
        }
        .second {
            text-transform: uppercase;
            color: white;
            font-family: "Rubik", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            line-height: 74px;
            @media (max-width: $md2+px) {
                font-size: 42px;
                line-height: normal;
            }
            @media (max-width: $md6+px) {
                font-size: 32px;
            }
        }
        .text {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
            font-size: 36px;
            line-height: 45px;
            width: 868px;
            margin-top: 20px;
            @media (max-width: $md2+px) {
                font-size: 24px;
                line-height: normal;
                width: 600px;
            }
            @media (max-width: $md5+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                font-size: 16px;
                margin-top: 10px;
            }
        }
        .btn {
            display: inline-flex;
            padding: 18px 30px;
            border-radius: 100px;
            background-color: white;
            color: white;
            position: relative;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            color: #000000;
            cursor: pointer;
            margin-top: 60px;
            margin-bottom: 134px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
            @media (max-width: $md2+px) {
                margin-top: 40px;
            }
            @media (max-width: $md6+px) {
                margin-top: 20px;
                font-size: 16px;
                padding: 12px 22px;
            }
        }
    }
    .footer {
        height: 150px;
        backdrop-filter: blur(20px);
        position: relative;
        z-index: 4;
        @media (max-width: $md6+px) {
            height: 100px;
        }
    }
    .penta {
        transition: all 1s;
        position: absolute;
        z-index: 3;
        top: 180px;
        width: 580px;
        right: 0;
        @media (max-width: $md1+px) {
            width: 400px;
            top: 320px;
        }
        @media (max-width: $md2+px) {
            top: 250px;
        }
        @media (max-width: $md4+px) {
            width: 250px;
            top: 340px;
            right: 50px;
        }
        @media (max-width: 560px) {
            top: 390px;
        }
        @media (max-width: $md6+px) {
            width: 200px;
            top: 350px;
        }
        @media (max-width: 430px) {
            top: 370px;
            right: 10px;
        }
    }
}
