@import "../../../styles/_var.scss";
.our {
}
.ourBody {
    margin-bottom: 150px;
    @media (max-width: $md6+px) {
        margin-bottom: 120px;
    }
}
.ourTitle {
    margin-bottom: 60px;
}
.ourTopRow {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin-bottom: 40px;
    @media (max-width: $md2+px) {
        width: 760px;
        margin-bottom: 10px;
    }
}
.ourTopRowColumn {
    font-family: "PT Root UI", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #3f778a;
    text-align: center;
    max-width: 100px;
    width: 100px;
    @media (max-width: $md2+px) {
        font-size: 20px;
    }
    &:last-child {
        flex: 0 1 auto;
        text-align: right;
    }
    &:first-child {
        width: 395px !important;
        max-width: 395px;
        text-align: left;
        @media (max-width: $md2+px) {
            width: 325px !important;
            max-width: 325px;
        }
    }
}
.ourRow {
    display: flex;
    justify-content: space-between;
    width: 1000px;
    padding: 30px 0;
    align-items: center;
    @media (max-width: $md2+px) {
        padding: 20px 0;
    }
}
.ourRowColumn {
    text-align: center;
    font-family: "PT Root UI", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #515151;
    max-width: 100px;
    width: 100px;
    @media (max-width: $md2+px) {
        font-size: 20px;
    }
    &:last-child {
        flex: 0 1 auto;
        text-align: right;
    }
    &:first-child {
        width: 395px !important;
        max-width: 395px;
        text-align: left;
        font-weight: 700;
        @media (max-width: $md2+px) {
            width: 325px !important;
            max-width: 325px;
        }
    }
}
.ourRowSell {
    width: 100px;
    height: 50px;
    left: 1213px;
    top: 2063px;
    background: #ffe5be;
    border-radius: 30px;
    font-family: "PT Root UI", sans-serif;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    color: #515151;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
        background-color: #ffd596;
    }
}
.ourRowBuy {
    width: 89px;
    height: 50px;
    left: 1323px;
    top: 2063px;
    background: #c6edc8;
    border-radius: 30px;
    font-family: "PT Root UI", sans-serif;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    color: #515151;
    cursor: pointer;
    transition: all 0.3s linear;
    margin-left: 20px;
    &:hover {
        background-color: #a4f2a9;
    }
}
.ourStartBtn {
    width: 250px;
    height: 61px;
    margin-top: 40px;
    background: #3f778a;
    border-radius: 100px;
    font-family: "PT Root UI", sans-serif;
    font-weight: 500;
    transition: all 0.3s linear;
    font-size: 20px;
    line-height: 61px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    align-items: center;
    @media (max-width: $md6+px) {
        font-size: 16px;
        height: 49px;
        line-height: 49;
        width: 210px;
    }
    img {
        margin-left: 10px;
    }
}
.ourRowWrapper {
    display: flex;
    justify-content: space-between;
    transition: all 0.3s linear;
    align-items: center;
    &:hover {
        transform: scale(1.02);
    }
}
.ourRowWrapperBtns {
    display: flex;
    @media (max-width: $md2+px) {
        margin-left: 30px;
    }
}
.globalWrap {
    @media (max-width: $md3+px) {
        overflow: auto;
    }
}
.globalWrapContent {
    @media (max-width: $md3+px) {
        width: 1000px;
    }
}
