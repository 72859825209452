@import "../../../styles/_var.scss";
.most {
    position: relative;
    z-index: 40;
}
.mostBody {
    margin-bottom: 150px;
    @media (max-width: $md4+px) {
        margin-bottom: 100px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 70px;
    }
}
.mostTitle {
    margin-bottom: 34px;

    @media (max-width: $md4+px) {
        margin-bottom: 10px;
    }
}
.mostSubtitle {
    max-width: 977px;

    font-family: "PT Root UI", sans-serif;
    font-size: 36px;
    line-height: 1.4;
    color: #515151;
    margin-bottom: 40px;

    @media (max-width: $md4+px) {
        max-width: 800px;
        margin: 0 0 40px 0;
    }

    @media (max-width: $md2+px) {
        font-size: 24px;
        max-width: 700px;
        margin-bottom: 20px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
.mostText {
    max-width: 616px;
    font-family: "PT Root UI", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 80px;
    color: #515151;
    @media (max-width: $md2+px) {
        margin-bottom: 60px;

        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        margin: 0 0 50px 0;
    }
    @media (max-width: $md6+px) {
        margin: 0 0 40px 0;
        font-size: 14px;
    }
}
.mostSpoiler {
    max-width: 930px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
    &:after {
        content: "";
        display: "inline-block";
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1124px;
        height: 1px;
        display: block;
        background: #dedede;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        @media (max-width: $md4+px) {
            width: 900px;
        }
    }
}
.mostSpoilerTitle {
    padding: 30px 0;
    font-family: "PT Root UI", sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #3f778a;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    @media (max-width: $md4+px) {
        padding-right: 200px;
    }
    @media (max-width: $md5+px) {
    }
    @media (max-width: 530px) {
        padding-right: 150px;
    }
    @media (max-width: 450px) {
        padding-right: 100px;
    }
    @media (max-width: 400px) {
        padding-right: 70px;
    }
    @media (max-width: $md2+px) {
        font-size: 24px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 32px;
        height: 1px;
        background-color: #3f778a;
        @media (max-width: $md6+px) {
            width: 16px;
            top: 1px;
        }
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1px;
        height: 32px;
        background-color: #3f778a;
        @media (max-width: $md6+px) {
            height: 16px;
            right: 7px;
        }
    }
    &.active {
        &:before {
            transform: rotate(90deg);
        }
    }
}
.mostSpoilerContent {
    font-family: "PT Root UI", sans-serif;

    font-size: 20px;
    line-height: 1.4;
    color: #515151;
    max-width: 616px;

    position: relative;
    margin-bottom: 10px;
    display: none;
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    ul,
    ol {
        position: relative;
        left: 22px;
    }
    ul li {
        list-style-type: disc;
    }
    &:hover {
        &:after {
            transform: scale(1.5);
        }
    }
    &.active {
        display: block;
        &:last-child {
            margin-bottom: 60px;
        }
    }

    &.star {
        &:after {
            display: block;
        }
    }
}
