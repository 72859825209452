@import "../../../styles/_var.scss";
.security {
}
.securityBody {
    margin-bottom: 150px;
    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}
.securityTitle {
    margin-bottom: 20px;
    text-align: center;
}
.securitySubtitle {
    font-family: "PT Root UI", sans-serif;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    max-width: 587px;
    text-align: center;
    color: #515151;
    margin: 0 auto 50px auto;
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.securityRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.securityRowColumn {
    cursor: pointer;
    &:hover {
        .securityRowIcon {
            transform: scale(1.2);
        }
    }
    &:nth-child(1) {
        flex: 0 1 242px;
    }
    &:nth-child(2) {
        flex: 0 1 222px;
    }
    &:nth-child(3) {
        flex: 0 1 302px;
    }
    &:nth-child(4) {
        flex: 0 1 340px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 24% !important;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 40% !important;
        margin-bottom: 20px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100% !important;
    }
}
.securityRowIcon {
    text-align: center;
    margin-bottom: 10px;
    img {
        width: 111px;
        @media (max-width: $md2+px) {
            width: 80px;
        }
    }
    transition: all 0.3s linear;
}
.securityRowColumnText {
    font-family: "PT Root UI", sans-serif;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
    color: #000000;

    @media (max-width: $md5+px) {
        max-width: 320px;
        margin: 0 auto;
    }
    @media (max-width: $md2+px) {
        font-size: 16px;
    }
}
