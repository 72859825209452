// @import "parts/titles.module.scss";
// @import "parts/buttons.module.scss";
// @import "parts/headerSlider.module.scss";
// @import "parts/collectionsColumn.module.scss";
// @import "parts/baner.module.scss";
// @import "parts/otherPageColumn.module.scss";
@import "./_var.scss";
body,
html {
    background-color: #d3dad6;
    color: #21211d;
    overflow-x: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.font-48 {
    font-size: 48px !important;
    font-weight: 400 !important;
    font-family: "Rubik", sans-serif !important;
    line-height: 1.3 !important;
    @media (max-width: 992px) {
        font-size: 44px;
    }
    @media (max-width: 768px) {
        font-size: 40px !important;
    }
    @media (max-width: 480px) {
        font-size: 32px !important;
    }
}
.header-default {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: normal;
    color: #3f778a;
    @media (max-width: $md2+px) {
        font-size: 48px;
    }
    @media (max-width: $md6+px) {
        font-size: 42px;
    }
}
.btn-anim {
    position: relative;
}
.btn-anim:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn-anim::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
}
.btn-anim::after {
    background-color: #fff;
}
.btn-anim:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
.btn-anim-dark {
    position: relative;
}
.btn-anim-dark:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn-anim-dark::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
}
.btn-anim-dark::after {
    background-color: #3f778a;
}
.btn-anim-dark:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
.blueTitle {
    font-family: "Rubik", sans-serif;
    font-size: 96px;
    line-height: 1;
    color: #3f778a;
    @media (max-width: $md2+px) {
        font-size: 48px;
    }
    @media (max-width: $md6+px) {
        font-size: 42px;
    }
}

//   @media (max-width: $md2+px) {
//         font-size: 48px;
//     }
//     @media (max-width: $md6+px) {
//         font-size: 42px;
//     }
