@import "../../../styles/_var.scss";
.grab {
}
.grabBody {
    margin-bottom: 150px;
    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}
.grabTitle {
    @media (max-width: $md5+px) {
    }
}
.grabSubtitle {
    font-family: "PT Root UI", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 60px;
    @media (max-width: $md4+px) {
        margin-bottom: 50px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 40px;
    }
    @media (max-width: $md2+px) {
    }
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
    }
    @media (max-width: $md2+px) {
        font-size: 24px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
.grabRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $md4+px) {
        justify-content: center;
    }
}
.grabRowColumn {
    width: 800px;
    height: 300px;
    overflow: hidden;
    cursor: pointer;
    background-color: #e0eaee;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    @media (max-width: $md1+px) {
        width: 640px;
    }
    @media (max-width: $md2+px) {
        width: 590px;
    }
    @media (max-width: $md3+px) {
        width: 485px;
    }
    filter: drop-shadow(0px 4px 7px rgba(47, 94, 109, 0.2));
    &:after {
        transition: all 0.3s linear;
    }
    &:hover {
        .grabRowColumnTitle,
        .grabRowColumnSubtitle {
            top: -15px;
        }
    }
    &:nth-child(1) {
        @media (max-width: $md4+px) {
            order: 1;
        }
        padding: 40px;
        .grabRowColumnSubtitle {
            max-width: 302px;
        }
        @media (max-width: $md6+px) {
            padding: 20px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../../../assets//trading/2/1.png") right 0 no-repeat;
        }
        &:hover {
            &:after {
                transform: scale(1.1) translate(-30px, 0px);
                @media (max-width: $md2+px) {
                    transform: scale(1.12) translate(-30px, 0px);
                }
                @media (max-width: $md3+px) {
                    transform: scale(1.15) translate(-30px, 0px);
                }
                @media (max-width: $md6+px) {
                    transform: scale(1.2) translate(-30px, 0px);
                }
            }
        }
    }
    &:nth-child(2) {
        @media (max-width: $md4+px) {
            order: 3;
        }
        padding: 40px 40px 40px 388px;
        .grabRowColumnSubtitle {
            max-width: 358px;
        }
        @media (max-width: $md1+px) {
            padding: 40px 40px 40px 288px;
        }
        @media (max-width: $md3+px) {
            padding: 40px 40px 40px 188px;
        }
        @media (max-width: $md6+px) {
            padding: 30px 20px 30px 20px;
        }

        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../../../assets//trading/2/2.png") 0 0 no-repeat;
        }
        &:hover {
            &:after {
                transform: scale(1.1) translate(30px, -5px);
                @media (max-width: $md2+px) {
                    transform: scale(1.12) translate(30px, -5px);
                }
                @media (max-width: $md3+px) {
                    transform: scale(1.15) translate(30px, -5px);
                }
                @media (max-width: $md6+px) {
                    transform: scale(1.2) translate(30px, -5px);
                }
            }
        }
    }
    &:nth-child(3) {
        @media (max-width: $md4+px) {
            order: 2;
        }
        padding: 90px 40px 40px 40px;
        .grabRowColumnSubtitle {
            max-width: 302px;
        }
        .grabRowColumnTitle {
            max-width: 302px;
        }

        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../../../assets//trading/2/3.png") right 0 no-repeat;
        }
        &:hover {
            &:after {
                transform: scale(1.1) translate(-30px, 0px);
                @media (max-width: $md2+px) {
                    transform: scale(1.12) translate(-30px, 0px);
                }
                @media (max-width: $md3+px) {
                    transform: scale(1.15) translate(-30px, 0px);
                }
                @media (max-width: $md6+px) {
                    transform: scale(1.2) translate(-30px, 0px);
                }
            }
        }
        @media (max-width: $md3+px) {
            padding: 100px 40px 40px 40px;
        }
        @media (max-width: $md6+px) {
            padding: 180px 20px 30px 20px;
        }
    }
    &:nth-child(4) {
        @media (max-width: $md4+px) {
            order: 4;
        }
        padding: 80px 40px 40px 388px;
        @media (max-width: $md1+px) {
            padding: 40px 40px 40px 288px;
        }
        @media (max-width: $md3+px) {
            padding: 100px 40px 40px 188px;
        }
        @media (max-width: $md6+px) {
            padding: 130px 40px 40px 20px;
        }
        .grabRowColumnSubtitle {
            max-width: 302px;
        }
        .grabRowColumnTitle {
            max-width: 302px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("../../../assets//trading/2/4.png") 0 0 no-repeat;
        }
        &:hover {
            &:after {
                transform: scale(1.1) translate(30px, 0px);
                @media (max-width: $md2+px) {
                    transform: scale(1.12) translate(30px, 0px);
                }
                @media (max-width: $md3+px) {
                    transform: scale(1.15) translate(30px, 0px);
                }
                @media (max-width: $md6+px) {
                    transform: scale(1.2) translate(30px, 0px);
                }
            }
        }
    }
}
.grabRowColumnTitle {
    font-family: "PT Root UI", sans-serif;
    font-weight: 700;
    font-size: 33px;
    line-height: 1.4;
    color: #3f778a;
    transition: all 0.3s linear;
    position: relative;
    z-index: 3;
    top: 0;
    margin-bottom: 10px;
    @media (max-width: $md3+px) {
        font-size: 30px;
    }
}
.grabRowColumnSubtitle {
    font-family: "PT Root UI", sans-serif;
    position: relative;
    font-size: 18px;
    z-index: 3;
    line-height: 1.5;
    top: 0;
    transition: all 0.3s linear;
    color: #515151;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
}
