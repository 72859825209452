@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import "styles/_fonts", "styles/_var.scss", "styles/_mixins.scss", "styles/_null", "styles/_container",
  "styles/_global";

@font-face {
  font-family: Furore;
  src: url("fonts/Furore.otf");
}

@font-face {
  font-family: Inter;
  src: url("fonts/Inter.ttf");
}

@font-face {
  font-family: Bebas;
  src: url("fonts/Bebas_Neue_Cyrillic.ttf");
}

body {
  padding: 0;
  margin: 0;
  background-color: #fffbfa;
  color: rgba(63, 119, 138, 1);
  padding: 0;
  margin: 0;
  background-color: white;
  color: rgba(63, 119, 138, 1);
}

.overflow-hidden {
  overflow: hidden;
}

.c-pointer {
  cursor: pointer;
}

.c-white {
  color: white;
}
.c-black {
  color: black;
}

.d-none {
  display: none;
}
.d-flex {
  display: flex;
}

.nb-desktop {
  display: block;
}
.nd-burger {
  display: none;
}
.nb-mobile {
  display: none;
}

@media (max-width: 1540px) {
  .nb-desktop {
    display: none;
  }
  // .nd-burger {
  //   display: block;
  // }
  .nb-mobile {
    display: block;
  }
}
@media (max-width: 1030px) {
  .nb-desktop {
    display: none;
  }
  // .nd-burger {
  //   display: none;
  // }
  .nb-mobile {
    display: block;
  }
}

.btn-anim:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn-anim::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.btn-anim::after {
  background-color: #fff;
}
.btn-anim:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.ta-left {
  text-align: left !important;
}

.btn-anim-dark:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn-anim-dark::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.btn-anim-dark::after {
  background-color: #3f778a;
}
.btn-anim-dark:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.h {
  &-1 {
    font-family: Bebas;
    font-size: 64px;
    line-height: 74px;
  }

  &-2 {
    font-family: Bebas;
    font-size: 48px;
    line-height: 60px;
  }

  &-3 {
    font-family: Bebas;
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
  }
}

.content {
  &-1 {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
  }

  &-2 {
    font-family: "Inter";
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
  }

  &-3 {
    font-family: "Inter";
    font-size: 20px;
    line-height: 24px;
  }

  &-4 {
    font-family: "Inter";
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  &-5 {
    font-family: "Inter";
    font-size: 24px;
    line-height: 36px;
  }

  &-6 {
    font-family: "Inter";
    font-size: 10px;
    line-height: 15px;
    font-weight: 700;
  }

  &-7 {
    font-family: "Inter";
    font-size: 10px;
    line-height: 15px;
  }
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.border-0 {
  // border-bottom: none;
  // border-image: none;
}

.shadow-button {
  box-shadow: 0px 0px 38px -16px rgba(0, 255, 240, 1);
}

// ::-webkit-scrollbar {
//   width: 4px;
//   background: white;
// }

// ::-webkit-scrollbar-thumb {
//   background: #333;
// }

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.decoration-none {
  text-decoration: none;
}

.wrapper {
  overflow: hidden;
  // position: absolute;
}

.coin {
  z-index: 11;
  display: block;
  &-apple {
    width: 100px;
  }

  &-bitcoin {
    width: 115px;
  }

  &-ethereum {
    width: 125px;
  }

  &-facebook {
    width: 160px;
  }

  &-tesla {
    width: 95px;
  }
}

// loader

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
