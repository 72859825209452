@import '../../../styles/var';

.marketOverview {
    position: relative;
    margin-top: 150px;
    margin-bottom: 170px;
    @media(max-width: $md6+px) {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    &:hover {
        @media(min-width: $md4+px) {
            .phone_01 {
                transform: rotate(-10deg);
                top: 30px!important;
            }
            .phone_02 { 
                transform: rotate(10deg);
                top: 120px!important;
            }   
        }
    }
    .left {
        .text {
            margin-top: 20px;
            width: 646px;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #515151;
            @media(max-width: $md2+px) {
                font-size: 16px;
                width: 550px;
            }
            @media(max-width: $md3+px) {
                width: 465px;
            }
            @media(max-width: $md4+px) {
                width: 100%;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }
    }
    .right {
        @media(max-width: $md4+px) {
            display: block;
        }
        img {
            position: absolute;
            transition: all 1s;
            @media(max-width: $md4+px) {
                display: none;
            }
        }
        .chart {
            top: 100px;
            right: 0;
            width: 720px;
            @media(max-width: $md1+px) {
                width: 580px;
                top: 110px;
            }
            @media(max-width: $md2+px) {
                top: 60px;
            }
            @media(max-width: $md3+px) {
                width: 500px;
                top: 100px;
            }
        }
        .phone_01 {
            top: 60px;
            right: 350px;
            width: 180px;
            @media(max-width: $md1+px) {
                right: 300px;
            }
            @media(max-width: $md2+px) {
                width: 150px;
                right: 270px;
            }
            @media(max-width: $md3+px) {
                right: 220px;
            }
        }
        .phone_02 {
            top: 100px;
            right: 230px;
            width: 250px;
            @media(max-width: $md1+px) {
                right: 180px;
            }
            @media(max-width: $md2+px) {
                width: 200px;
                top: 70px;
                right: 160px;
            }
            @media(max-width: $md3+px) {
                right: 110px;
            }
        }
        .together {
            position: relative;
            display: none;
            @media(max-width: $md4+px) {
                display: flex;
                width: 80%;
                margin: 30px auto 0 auto;
            }
            @media(max-width: $md6+px) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}